<template lang="html">
    <svg width="512" height="512" viewBox="0 0 512 512">
        <g fill="none" fill-rule="evenodd">
            <circle cx="256.5" cy="255.5" r="149.5" stroke="currentColor" stroke-width="30" />
            <path
                stroke="currentColor"
                stroke-linecap="square"
                stroke-width="30"
                d="M257.25,187.75 L256.75,322.25"
            />
            <path
                stroke="currentColor"
                stroke-linecap="square"
                stroke-width="30"
                d="M257.25,187.75 L256.75,322.25"
                transform="scale(-1 1) rotate(89.787 0 -2.958)"
            />
        </g>
    </svg>
</template>

<script>
export default {};
</script>

<style lang="scss"></style>
