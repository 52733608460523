var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Row-Wrap",
    class: [{
      'is-empty': _vm.mEmployees.length === 0
    }]
  }, [_vm.mEmployees.length > 0 ? _c('Row', {
    key: -1,
    staticClass: "Row-Header",
    attrs: {
      "parentHover": false,
      "items": {
        row: _vm.header
      },
      "index": -1
    },
    on: {
      "onClickColumn": function onClickColumn(e) {
        return _vm.handleHeaderClick(e);
      }
    }
  }) : _vm._e(), _vm._l(_vm.mEmployees, function (employee, i) {
    return _c('Row', {
      key: i,
      attrs: {
        "isDropdown": "",
        "parentHover": false,
        "isReadOnly": employee.isReadOnly,
        "items": employee,
        "index": i
      },
      on: {
        "onClick": function onClick(e) {
          return _vm.handleEmployeeClick(e);
        }
      }
    });
  }), _vm.mEmployees.length === 0 ? _c('EmptyState') : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }