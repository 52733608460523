<template lang="html">
    <div class="Row-Wrap" :class="[{ 'is-empty': mEmployees.length === 0 }]">
        <Row
            v-if="mEmployees.length > 0"
            :parentHover="false"
            :items="{ row: header }"
            :key="-1"
            :index="-1"
            class="Row-Header"
            @onClickColumn="e => handleHeaderClick(e)"
        />
        <Row
            isDropdown
            v-for="(employee, i) in mEmployees"
            :parentHover="false"
            :isReadOnly="employee.isReadOnly"
            :items="employee"
            :key="i"
            :index="i"
            @onClick="e => handleEmployeeClick(e)"
        />
        <EmptyState v-if="mEmployees.length === 0" />
    </div>
</template>

<script type="text/javascript">
import Row from '@/components/Row';
import EmptyState from '@/components/EmptyState.vue';

export default {
    name: 'ItemTable',
    components: {
        Row,
        EmptyState,
    },
    props: {
        employees: {
            type: Array,
        },
    },
    data: () => {
        return {
            activeRow: -1,
            activeBar: -1,
            sortState: {
                column: 'Anzeigen', // 'Fahrer Nr.
                ascending: true,
            },
        };
    },
    computed: {
        header() {
            return [
                {
                    name: `Fahrer`,
                    value: null,
                },
                {
                    name: `Fahrer Nr.`,
                    value: null,
                },
                {
                    name: `Anzeigen`,
                    value: null,
                },
            ].map(header => {
                let indicator = '';
                if (this.sortState.column === header.name) {
                    indicator = this.sortState.ascending ? ' ▲' : ' ▼';
                }
                return { ...header, name: header.name + indicator };
            });
        },
        mEmployees() {
            return this.employees
                ? this.sortEmployees([...this.formatEmployees(this.employees)])
                : [];
        },
    },
    methods: {
        handleHeaderClick(column) {
            const columnName = column.name
                .replace('▲', '')
                .replace('▼', '')
                .trim();
            if (this.sortState.column === columnName) {
                this.sortState.ascending = !this.sortState.ascending; // Toggle sort direction
            } else {
                this.sortState.column = columnName;
                this.sortState.ascending = true; // Default to ascending
            }
        },
        sortEmployees(arr) {
            const { column, ascending } = this.sortState;

            // First sort based on visibility
            arr.sort((a, b) => {
                if (
                    a.row.find(r => r.name === 'Anzeigen').props.value &&
                    !b.row.find(r => r.name === 'Anzeigen').props.value
                )
                    return -1;
                if (
                    !a.row.find(r => r.name === 'Anzeigen').props.value &&
                    b.row.find(r => r.name === 'Anzeigen').props.value
                )
                    return 1;
                return 0;
            });

            // Then sort based on the selected header, if any
            if (column) {
                const sortFn = (a, b) => {
                    const aValue = a.row.find(r => r.name === column).value;
                    const bValue = b.row.find(r => r.name === column).value;
                    if (aValue < bValue) return ascending ? -1 : 1;
                    if (aValue > bValue) return ascending ? 1 : -1;
                    return 0;
                };
                arr.sort(sortFn);
            }

            return arr;
        },

        handleEmployeeClick(p) {
            const target = p.event.target;
            const isCheckbox = target.classList.contains('Checkbox') || target.type === 'checkbox';
            if (isCheckbox) return;
            this.$emit('onEmployeeClick', p);
        },
        formatEmployees(employees) {
            const mEmployees = employees

                .sort((a, b) => {
                    if (a.isVisible && !b.isVisible) return -1;
                    if (!a.isVisible && b.isVisible) return 1;
                    if (a.name < b.name) return -1;
                    if (a.name > b.name) return 1;
                    return 0;
                })
                .map(employee => {
                    return {
                        id: employee.driverNumber,
                        isReadOnly: employee.isSystemDriver,
                        row: [
                            { name: 'Fahrer', value: employee.name || '' },
                            { name: 'Fahrer Nr.', value: employee.driverNumber },
                            {
                                name: 'Anzeigen',
                                props: {
                                    keyName: 'isVisible',
                                    isDisabled: employee.isSystemDriver,
                                    value: employee.isVisible,
                                    id: employee.driverNumber,
                                    onCheck: isVisible => {
                                        this.$emit('onEmployeeVisibilityChange', {
                                            isVisible,
                                            employee,
                                        });
                                    },
                                },
                                component: 'Checkbox',
                            },
                        ],
                    };
                });
            return mEmployees;
        },
    },
};
</script>

<style lang="scss" scoped>
.Row-Wrap {
    width: 100%;
    position: sticky;
    top: 0;

    > * {
        min-width: 600px;
    }

    &.is-empty {
        > * {
            min-width: 0;
        }
    }
}
</style>
