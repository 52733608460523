var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "512",
      "height": "512",
      "viewBox": "0 0 512 512"
    }
  }, [_c('g', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd"
    }
  }, [_c('circle', {
    attrs: {
      "cx": "256.5",
      "cy": "255.5",
      "r": "149.5",
      "stroke": "currentColor",
      "stroke-width": "30"
    }
  }), _c('path', {
    attrs: {
      "stroke": "currentColor",
      "stroke-linecap": "square",
      "stroke-width": "30",
      "d": "M257.25,187.75 L256.75,322.25"
    }
  }), _c('path', {
    attrs: {
      "stroke": "currentColor",
      "stroke-linecap": "square",
      "stroke-width": "30",
      "d": "M257.25,187.75 L256.75,322.25",
      "transform": "scale(-1 1) rotate(89.787 0 -2.958)"
    }
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }